import { LucideIconName } from '~/core/ui/IconWrapper'

export const TENANT_PLANS_STATUS = {
  trial: 'trial',
  paid: 'paid',
  expired: 'expired'
}
export const KEY_STORAGE_BUTTON_UPGRADE_TIME = 'KEY_STORAGE_BUTTON_UPGRADE_TIME'

export const PLAN_FEATURE_KEYS = {
  requisition: 'requisition',
  referral: 'referral',
  job_board: 'job_board',
  ai_resume_parser: 'ai_resume_parser',
  cv_template: 'cv_template',
  additional_field: 'additional_field',
  recommendation: 'recommendation',
  employee_profile: 'employee_profile',
  import_job: 'import_job',
  security_setting: 'security_setting',
  audit_logs: 'audit_log'
}

export const dataPlansFeatures: {
  iconName: LucideIconName
  label: string
  listTest: string[]
}[] = [
  {
    iconName: 'FileSearch',
    label: 'feature01Title',
    listTest: [
      'feature01Description01',
      'feature01Description02',
      'feature01Description03',
      'feature01Description04',
      'feature01Description05'
    ]
  },
  {
    iconName: 'Settings',
    label: 'feature02Title',
    listTest: [
      'feature02Description01',
      'feature02Description02',
      'feature02Description03',
      'feature02Description04',
      'feature02Description05',
      'feature02Description06'
    ]
  },
  {
    iconName: 'ListChecks',
    label: 'feature03Title',
    listTest: [
      'feature03Description01',
      'feature03Description02',
      'feature03Description03',
      'feature03Description04',
      'feature03Description05',
      'feature03Description06',
      'feature03Description07',
      'feature03Description08',
      'feature03Description09',
      'feature03Description10',
      'feature03Description11'
    ]
  },
  {
    iconName: 'PieChart',
    label: 'feature04Title',
    listTest: [
      'feature04Description01',
      'feature04Description02',
      'feature04Description03',
      'feature04Description04',
      'feature04Description05'
    ]
  }
]
