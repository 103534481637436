import { parseCookies } from 'nookies'
import { useCallback, useRef } from 'react'
import { HttpMethod, StringObject } from '~/core/@types/global'
import { SESSION_COOKIE_NAME } from '../constants/cookies'
import { DOWNLOAD_FILE_API } from '../constants/api'
import { PUBLIC_API_URL } from '../constants/env'

const AUTHORIZATION_TOKEN_HEADER = 'Authorization'

/**
 * @name useApiRequest
 * @description A hook to make API requests
 * 1. By default, it will use the `POST` method and will send the payload as
 * JSON
 * 2. Also, it will automatically add the [CSRF, Authorization] token to the request headers and
 * the App Check token if the SDK is initialized
 */
export function useApiRequest<Resp = unknown, Body = void>() {
  const headersRef = useRef<StringObject>({})

  /**
   * @variable Authorization
   * @description A user token to detect who is use app
   */
  const cookies = parseCookies()
  const authorizationToken = cookies[SESSION_COOKIE_NAME]

  return useCallback(
    async (params: {
      path: string
      body?: Body
      method?: HttpMethod
      extraParams?: { language?: string; timezone?: string }
      headers?: StringObject
    }) => {
      const payload = JSON.stringify(params.body)

      // initialize the headers if they are not defined
      if (!headersRef.current) {
        headersRef.current = params.headers ?? {}
      }

      // add the Authorization token to the request headers if it exists
      if (authorizationToken) {
        headersRef.current[
          AUTHORIZATION_TOKEN_HEADER
        ] = `Bearer ${authorizationToken}`
      }

      if (params.headers?.[AUTHORIZATION_TOKEN_HEADER]) {
        headersRef.current[AUTHORIZATION_TOKEN_HEADER] =
          params.headers[AUTHORIZATION_TOKEN_HEADER]
      }

      // execute the fetch request
      return executeFetchRequest<Resp>(
        params.path,
        payload,
        params.method,
        params.extraParams,
        headersRef.current
      )
    },
    [authorizationToken]
  )
}

async function executeFetchRequest<Resp = unknown>(
  url: string,
  payload: string,
  method = 'GET',
  extraParams?: { language?: string; timezone?: string },
  headers?: StringObject
) {
  const options: RequestInit = {
    method,
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      ...(headers ?? {})
    }
  }

  const methodsSupportingBody: HttpMethod[] = ['POST', 'PUT', 'PATCH']
  const supportsBody = methodsSupportingBody.includes(method as HttpMethod)

  if (payload && supportsBody) {
    const format = {
      payload: {
        ...JSON.parse(payload),
        ...extraParams
      }
    }
    options.body = JSON.stringify(format)
  }

  try {
    const response = await fetch(url, options)

    if (response.redirected) {
      return window.location.assign(response.url)
    }

    if (response.ok) {
      if (response.url === `${PUBLIC_API_URL}${DOWNLOAD_FILE_API}`) {
        return (await new Response(response.body).blob()) as any
      }
      return (await response.json()) as Promise<Resp>
    }

    return Promise.reject(await response.json())
  } catch (error) {
    return Promise.reject(error)
  }
}
