import { CareerPageSettingType } from '~/lib/features/careers/[id]/types'
import { combineDomainCareerPage } from '~/lib/features/careers/[id]/utilities'
import { PUBLIC_APP_URL, PUBLIC_HELP_CENTER_URL } from '../core/constants/env'
import {
  AGENCY_COMPANIES_URL,
  AGENCY_SETTINGS_COMPANY_STATUS_URL,
  AUTH_ERROR_URL,
  CALENDAR_LIST_URL,
  CANDIDATES_DETAIL_URL,
  CANDIDATES_LIST_URL,
  CAREERS_LIST_URL,
  COMPANY_NAME,
  CONTACT_US_URL,
  DEFAULT_URL,
  ERROR_404_URL,
  ERROR_500_URL,
  ERROR_ACCESS_DENIED_URL,
  ERROR_EXPIRED_PLAN_URL,
  EXTENSION_URL,
  INTERVIEW_DETAIL_URL,
  JOBS_CREATE_URL,
  JOBS_DETAIL_URL,
  JOBS_EDIT_URL,
  JOBS_LIST_URL,
  JOBS_TABS_DETAIL_URL,
  LOGIN_SSO_URL,
  LOGIN_URL,
  ONBOARDING_URL,
  PLACEMENTS_LIST_URL,
  PRIVACY_POLICY_URL,
  REFERRAL_LIST_URL,
  REGISTER_URL,
  REPORTS_HIRING_FUNNEL_URL,
  REPORTS_OVERVIEW_URL,
  REPORTS_PRODUCTIVITY_URL,
  REPORTS_TIME_TO_HIRE_URL,
  REQUISITION_CREATE_DUPLICATE_URL,
  REQUISITION_CREATE_URL,
  REQUISITION_EDIT_URL,
  REQUISITION_LIST,
  RESUMES_BUILDER_PREVIEW_URL,
  RESUMES_EDITOR_URL,
  SELECT_COMPANY_URL,
  SETTINGS_ACCOUNT_PREFERENCES_URL,
  SETTINGS_ACCOUNT_URL,
  SETTINGS_CAREERS_URL,
  SETTINGS_DEPARTMENTS_URL,
  SETTINGS_EMAIL_TEMPLATES,
  SETTINGS_LOCATIONS_URL,
  SETTINGS_MEMBERS_URL,
  SETTINGS_PROFILES_URL,
  SETTINGS_WORKSPACE_URL,
  SETTING_AUDIT_LOGS,
  SETTING_CUSTOM_FIELDS,
  SETTING_DISQUALIFY_REASONS,
  SETTING_HIRING_PIPELINES,
  SETTING_IMPORT,
  SETTING_INTERVIEW_KITS,
  SETTING_PLANS,
  SETTING_PLANS_EXPIRED,
  SETTING_PROFILE_TEMPLATES,
  SETTING_REFERRALS,
  SETTING_REQUISITIONS,
  SETTING_SECURITY,
  SETTING_TAGS,
  SHARE_CV_URL,
  TALENT_POOL_DETAIL_URL,
  TALENT_POOL_LIST_URL,
  TASKS_LIST_URL,
  TENANT_BLOCKED,
  TERMS_OF_USE_URL,
  VERIFY_EMAIL_SSO_URL,
  VERIFY_EMAIL_URL
} from '../core/constants/url'

const pathConfiguration = {
  error500: ERROR_500_URL,
  error404: ERROR_404_URL,
  errorAccessDenied: ERROR_ACCESS_DENIED_URL,
  tenantBlocked: TENANT_BLOCKED,
  errorExpiredPlan: ERROR_EXPIRED_PLAN_URL,
  default: DEFAULT_URL,
  register: REGISTER_URL,
  login: LOGIN_URL,
  loginSSO: LOGIN_SSO_URL,
  verifyEmail: VERIFY_EMAIL_URL,
  verifyEmailSSO: VERIFY_EMAIL_SSO_URL,
  authError: AUTH_ERROR_URL,
  onboarding: ONBOARDING_URL,
  selectCompany: SELECT_COMPANY_URL,
  contactUs: CONTACT_US_URL,
  termsOfUse: TERMS_OF_USE_URL,
  privacyPolicy: PRIVACY_POLICY_URL,
  companyName: COMPANY_NAME,
  candidates: {
    list: CANDIDATES_LIST_URL,
    detail: CANDIDATES_DETAIL_URL
  },
  requisitions: {
    list: REQUISITION_LIST,
    create: REQUISITION_CREATE_URL,
    edit: REQUISITION_EDIT_URL,
    duplicate: REQUISITION_CREATE_DUPLICATE_URL
  },
  jobs: {
    list: JOBS_LIST_URL,
    detail: JOBS_DETAIL_URL,
    edit: JOBS_EDIT_URL,
    create: JOBS_CREATE_URL,
    duplicateJob: (jobId: string) =>
      `${JOBS_CREATE_URL}?duplicate_job=${jobId}&id=${jobId}`,
    careerJobView: (tenantSlug: string, jobId: string) =>
      `${CAREERS_LIST_URL}/${tenantSlug}/${jobId}`,
    detailTab: (jobId: number) => JOBS_TABS_DETAIL_URL(jobId)
  },
  tasks: {
    list: TASKS_LIST_URL
  },
  careers: {
    list: CAREERS_LIST_URL,
    detail: (tenantSlug: string) => `${CAREERS_LIST_URL}/${tenantSlug}`,
    eventDetail: ({
      tenantSlug,
      eventId
    }: {
      tenantSlug?: string
      eventId?: number | string
    }) => `${tenantSlug}events/${eventId}`,
    jobDetail: ({
      tenantSlug,
      jobId
    }: {
      tenantSlug: string
      jobId: number | string
    }) => `${CAREERS_LIST_URL}/${tenantSlug}/${jobId}`,
    jobApplied: ({
      locale = 'en',
      tenantSlug,
      jobId
    }: {
      locale: string
      tenantSlug: string
      jobId: number | string
    }) =>
      `${
        locale === 'ja' ? '/ja' : ''
      }${CAREERS_LIST_URL}/${tenantSlug}/${jobId}/applied`,
    applyJob: ({
      locale = 'en',
      tenantSlug,
      jobId
    }: {
      locale: string
      tenantSlug: string
      jobId: number | string
    }) =>
      `${
        locale === 'ja' ? '/ja' : ''
      }${CAREERS_LIST_URL}/${tenantSlug}/${jobId}`,
    applyJobWithDomain: ({
      tenantSlug,
      jobId,
      careerSetting
    }: {
      tenantSlug: string
      jobId: number | string
      careerSetting?: CareerPageSettingType
    }) =>
      `${
        careerSetting ? combineDomainCareerPage(careerSetting) : PUBLIC_APP_URL
      }${CAREERS_LIST_URL}/${tenantSlug}/${jobId}`
  },
  calendar: {
    list: CALENDAR_LIST_URL
  },
  referral: {
    list: REFERRAL_LIST_URL,
    jobDetail: ({ jobId }: { jobId: number | string }) =>
      `${REFERRAL_LIST_URL}/jobs/${jobId}`
  },
  reports: {
    overview: REPORTS_OVERVIEW_URL,
    timeToHire: REPORTS_TIME_TO_HIRE_URL,
    productivity: REPORTS_PRODUCTIVITY_URL,
    hiringFunnel: REPORTS_HIRING_FUNNEL_URL
  },
  settings: {
    members: SETTINGS_MEMBERS_URL,
    departments: SETTINGS_DEPARTMENTS_URL,
    locations: SETTINGS_LOCATIONS_URL,
    workspace: SETTINGS_WORKSPACE_URL,
    account: SETTINGS_ACCOUNT_URL,
    profiles: SETTINGS_PROFILES_URL,
    preferences: SETTINGS_ACCOUNT_PREFERENCES_URL,
    careers: SETTINGS_CAREERS_URL,
    emailTemplates: SETTINGS_EMAIL_TEMPLATES,
    disqualifyReasons: SETTING_DISQUALIFY_REASONS,
    customFields: SETTING_CUSTOM_FIELDS,
    hiringPipelines: SETTING_HIRING_PIPELINES,
    interviewKits: SETTING_INTERVIEW_KITS,
    profileTemplates: SETTING_PROFILE_TEMPLATES,
    security: SETTING_SECURITY,
    tags: SETTING_TAGS,
    referrals: SETTING_REFERRALS,
    requisitions: SETTING_REQUISITIONS,
    plans: SETTING_PLANS,
    plans_expired: SETTING_PLANS_EXPIRED,
    import: SETTING_IMPORT,
    audit_logs: SETTING_AUDIT_LOGS
  },
  interviews: {
    detail: (interviewId: number) => INTERVIEW_DETAIL_URL(interviewId)
  },
  extension: {
    list: EXTENSION_URL
  },
  talentPool: {
    list: TALENT_POOL_LIST_URL,
    detail: (talentPoolId: number) => TALENT_POOL_DETAIL_URL(talentPoolId)
  },
  helpCenter: {
    ga4: `${PUBLIC_HELP_CENTER_URL}/product-guides/setting/company/career-page#google-analytics-4-integration`,
    referrals: `${PUBLIC_HELP_CENTER_URL}/product-guides/referral`,
    department: `${PUBLIC_HELP_CENTER_URL}/product-guides/setting/company/department`,
    teamMembers: `${PUBLIC_HELP_CENTER_URL}/product-guides/setting/company/team-members`,
    locations: `${PUBLIC_HELP_CENTER_URL}/product-guides/setting/company/location`,
    requisitions: `${PUBLIC_HELP_CENTER_URL}/product-guides/setting/workflow/requisition#create-approval-flow`,
    hiringPipelines: `${PUBLIC_HELP_CENTER_URL}/product-guides/setting/template/hiring-pipeline`,
    interviewKits: `${PUBLIC_HELP_CENTER_URL}/product-guides/track-evaluation/interview-kit`,
    profileTemplates: `${PUBLIC_HELP_CENTER_URL}/product-guides/track-evaluation/profile-templates`,
    jobBoard: `${PUBLIC_HELP_CENTER_URL}/product-guides/jobs/job-detail#id-4.-job-boards`,
    hiringTeam: `${PUBLIC_HELP_CENTER_URL}/product-guides/jobs/create-a-new-job#id-3.-hiring-team`,
    schedulePopup: `${PUBLIC_HELP_CENTER_URL}/product-guides/candidate/interview#schedule-an-interview`,
    createRequisition: `${PUBLIC_HELP_CENTER_URL}/product-guides/setting/workflow/requisition`,
    report: `${PUBLIC_HELP_CENTER_URL}/product-guides/report`,
    reportOverview: `${PUBLIC_HELP_CENTER_URL}/product-guides/report/overview`,
    reportTimeToHire: `${PUBLIC_HELP_CENTER_URL}/product-guides/report/time-to-hire`,
    reportHiringFunnel: `${PUBLIC_HELP_CENTER_URL}/product-guides/report/hiring-funnel`,
    reportTeamProductivity: `${PUBLIC_HELP_CENTER_URL}/product-guides/report/team-productivity`,
    plan: `${PUBLIC_HELP_CENTER_URL}/product-guides/plan`,
    inviteMember: `${PUBLIC_HELP_CENTER_URL}/product-guides/setting/company/team-members#invite-member`
  },
  agency: {
    companies: AGENCY_COMPANIES_URL,
    companyDetail: (id: number) => `${AGENCY_COMPANIES_URL}/${id}`,

    settings: {
      companyStatus: AGENCY_SETTINGS_COMPANY_STATUS_URL
    }
  },
  placements: {
    list: PLACEMENTS_LIST_URL
  },
  resumes: {
    detail: RESUMES_EDITOR_URL,
    builderPreview: RESUMES_BUILDER_PREVIEW_URL
  },
  shareCV: (id?: string) => `${PUBLIC_APP_URL}${SHARE_CV_URL(id)}`
}

export default pathConfiguration
