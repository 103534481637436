import logger from '~/core/logger'
import { Maybe } from '../@types/global'

/**
 * @description Serializes safely the user object
 * @param cookie
 */
export function getUserInfoByCookie({
  authCookie,
  tenantsCookie,
  currentTenantCookie
}: {
  authCookie: string
  tenantsCookie: string
  currentTenantCookie: string
}) {
  if (!authCookie) return null

  const user = JSON.parse(authCookie)
  const tenantsObj = tenantsCookie ? JSON.parse(tenantsCookie) : { data: [] }
  const currentTenantObj = currentTenantCookie
    ? JSON.parse(currentTenantCookie)
    : {}

  try {
    return {
      id: getValue(user?.id),
      email: getValue(user?.email),
      fullName: getValue(user?.fullName),
      phoneNumber: getValue(user?.phoneNumber),
      jobTitle: getValue(user?.jobTitle),
      provider: getValue(user?.provider),
      fromProduct: getValue(user?.fromProduct),
      language: getValue(user?.language),
      timezone: getValue(user?.timezone),
      emailVerified: getValue(user?.emailVerified),
      avatarVariants: user?.avatarVariants,
      defaultColour: getValue(user?.defaultColour),
      tenants: tenantsObj,
      currentTenant: currentTenantObj,
      ownTenant: getValue(user?.ownTenant)
    }
  } catch (e) {
    logger.warn(
      {
        userId: user.id
      },
      `User was not found`
    )

    return
  }
}

/**
 * @description Guards against undefined values
 * @param value
 */
function getValue<T>(value: Maybe<T>) {
  return value ?? null
}
